import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button, TextareaAutosize } from "@mui/material";

import Title from "../../../common/Title";
import InputFieldBox from "../../../common/InputFieldBox";

import useAlert from "../../../../hooks/useAlert";
import useAxios from "../../../../hooks/useAxios";
import { __wifiCallingAdminUltra } from "../../../../api/sim";

const WifiCallingForm = () => {
  const [rawData, setRawData] = useState("");
  const [linesData, setLinesData] = useState([]);
  const [formData, setFormData] = useState(null);
  const [enable, setEnable] = useState(true);

  const { setAlertMessage } = useAlert();
  const initialData = { lines: [] };
  const [response, call, loading] = useAxios({
    ...__wifiCallingAdminUltra,
  });
  const nav = useNavigate();

  const lineValidationSchema = yup.object({
    iccid: yup
      .string("iccid")
      .min(19, "Should be exactly 19 or 20 digits long")
      .max(20, "Should be exactly 19 or 20 digits long")
      .required(),
    zipcode: yup
      .string("areacode")
      .test("Digits only", "The field should have 5 digits only", (value) =>
        value && value.length ? /^\d{5}$/.test(value) : true
      )
      .required(),
    street1: yup.string("street1").required(),
    street2: yup.string("street2").optional(),
    city: yup.string("city").required(),
    state: yup.string("state").required(),
  });

  useEffect(() => {
    if (formData) {
      call({
        data: {
          skip_zip_checks: true,
          lines: [...linesData.map((val) => ({ ...val, enable: enable }))],
        },
      });
    }
  }, [formData]);

  useEffect(() => {
    if (!response) return;
    if (response.status) {
      setAlertMessage({
        type: "success",
        message: "Wifi Calling update queue started",
      });
      setLinesData([]);
      nav("/ultra/wifi_calling");
    }
    formik.setSubmitting(false);
  }, [response]);

  const validateFunction = (data) => {
    const errors = {};
    const formatedLines = [];

    if (!data) {
      return errors;
    }

    const iccids = [];

    const lines = data.split("\n");
    lines.some((line, idx) => {
      const data = line.split(",");
      if (data.length !== 6) {
        errors.lines = `Invalid format in line ${idx + 1}: ${line}`;
        return true;
      }

      try {
        const line_data = line.split(",");
        const obtainedData = {
          iccid: line_data[0].trim(),
          zipcode: line_data[1].trim(),
          street1: line_data[2].trim(),
          street2: line_data[3].trim(),
          city: line_data[4].trim(),
          state: line_data[5].trim(),
        };

        lineValidationSchema.validateSync(obtainedData);

        if (isNaN(obtainedData.iccid)) {
          throw new Error("Invalid Number");
        }
        formatedLines.push(obtainedData);

        iccids.push(obtainedData.iccid);

        if (iccids.indexOf(obtainedData.iccid) !== idx) {
          errors.lines = `Duplicate iccid value in line ${idx + 1} `;
          return true;
        }
      } catch (err) {
        console.log(err);
        errors.lines = `Invalid format in line ${idx + 1}: ${line}`;
        return true;
      }

      return false;
    });

    if (!errors.lines) {
      setLinesData(formatedLines);
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: { ...initialData },
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        obj[key] = values[key] || null;
        return obj;
      }, {});

      console.log(requestData);

      setFormData(requestData);
    },
    validateOnChange: true,
  });

  const handleParse = (inputFile) => {
    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      setRawData(target.result);
      formik.setErrors(validateFunction(target.result));
    };
    reader.readAsText(inputFile);
  };
  const handleFileChange = (e) => {
    const allowedExtensions = ["csv"];

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        formik.setErrors({
          ...formik.errors.lines,
          upload: "Please input a csv file",
        });
        return;
      }

      handleParse(inputFile);
    }
  };

  if (loading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Box
        sx={{ width: "100%" }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Title
          variant="h5"
          content="Wifi Calling Update"
          sx={{ mb: 3 }}
          gutterBottom
        />
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <FormControl
              error={Boolean(formik.errors.upload) ? true : false}
              sx={{ width: "100%" }}
            >
              <Button
                component="label"
                variant="outlined"
                startIcon={<UploadFileIcon />}
                sx={{ marginRight: "1rem" }}
              >
                Upload CSV
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              {Boolean(formik.errors.upload) ? (
                <FormHelperText id="upload-error-text">
                  {formik.errors.upload}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              error={Boolean(formik.errors.enable) ? true : false}
              sx={{ width: "100%" }}
            >
              <InputFieldBox
                isCheckbox={true}
                value={enable}
                onChange={(event) => setEnable(event.target.checked)}
                label="Enable/Disable Wifi Calling"
              />

              {Boolean(formik.errors.enable) ? (
                <FormHelperText id="enable-error-text">
                  {formik.errors.enable}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="lines">
              {"Add in the format"}
              <br></br>
              {"ICCID, Zipcode, Street1, [Street2], City, State"}
              <br></br>
              {"ICCID, Zipcode, Street1, [Street2], City, State"}
              <br></br>
            </InputLabel>
            <FormControl
              error={Boolean(formik.errors.lines) ? true : false}
              sx={{ width: "100%" }}
            >
              <TextareaAutosize
                multiline
                rows={6}
                aria-label="minimum height"
                placeholder={`Add in the format\n"ICCID, Zipcode, Street1, [Street2], City, State"`}
                style={{ minWidth: 550 }}
                name="lines"
                minRows={5}
                id="lines"
                value={rawData}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const data = e.target.value;
                  setRawData(data);
                  formik.setErrors(validateFunction(data));
                }}
              />
              {Boolean(formik.errors.lines) ? (
                <FormHelperText id="lines-error-text">
                  {formik.errors.lines}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid
            container
            sx={{ mt: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                disabled={formik.errors.lines || !linesData.length}
                onClick={() =>
                  !loading && !formik.isSubmitting && formik.submitForm()
                }
                size="large"
                loading={loading}
                variant="contained"
              >
                {"Wifi Calling Update"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default WifiCallingForm;
