import debounce from "lodash.debounce";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import Title from "../../../common/Title";
import ActivateList from "./ActivateList";
import TerminateBatch from "../../../common/TerminateBatch";

import { copyData } from "../../../../utils/copy";
import useAxios from "../../../../hooks/useAxios";
import useAlert from "../../../../hooks/useAlert";
import { CLIENTS, getValidRetryLines } from "../../../../constants/value";
import { __getActivate, __export, __retryOperation } from "../../../../api/sim";

const ActivateBatch = () => {
  const { batch_id } = useParams();
  const [data, setData] = useState([]);
  const nav = useNavigate();
  const [updateValid, setUpdateValid] = useState(false);

  const COPY_KEYS = [
    "id",
    "batch_id",
    "iccid",
    "status",
    "plan_id",
    "plan",
    "trial_count",
    "areacode",
    "zipcode",
    "msisdn",
    "last_updated",
  ];

  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 20000,
    total: 1,
  });
  const [response, call, loading] = useAxios({ ...__getActivate });
  const { setAlertMessage } = useAlert();
  const [retryResponse, retryCall, retryLoading] = useAxios({
    ...__retryOperation(batch_id),
  });
  const [exportResponse, exportCall, exportLoading] = useAxios({ ...__export });

  const debouncedFetchData = debounce((query) => {
    call(query);
  }, 500);

  useEffect(() => {
    debouncedFetchData({
      params: {
        client: CLIENTS.ultra,
        page_size: 20000,
        batch_id: batch_id,
      },
    });

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.page_size,
        total: response.data.total,
      });
      setData(response.data.data);
    }
  }, [response]);

  useEffect(() => {
    const retryLines = getValidRetryLines(data);

    if (retryLines && retryLines.length) {
      setUpdateValid(true);
    }
  }, [data]);

  useEffect(() => {
    if (exportResponse && exportResponse.status) {
      fileDownload(exportResponse.data, `activate_export-${batch_id}.csv`);
    }
  }, [exportResponse]);

  useEffect(() => {
    if (retryResponse && retryResponse.status) {
      setAlertMessage({ type: "success", message: "Activation retry queued" });
    }
  }, [retryResponse]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title
        variant="h5"
        content={`Activate Batch ID: ${batch_id}`}
        gutterBottom
      />
      {response && (
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item sx={{ mb: "10px" }}>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="primary"
                disabled={loading || exportLoading}
                onClick={() =>
                  exportCall({
                    responseType: "blob",
                    params: {
                      action: "activate",
                      batch_id: batch_id,
                    },
                  })
                }
              >
                <FileDownloadIcon sx={{ mr: "5px" }} /> Export
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="secondary"
                disabled={loading || exportLoading}
                onClick={() => copyData(data, COPY_KEYS)}
              >
                <ContentCopyIcon sx={{ mr: "5px" }} /> Copy Data
              </Button>
            </Stack>
          </Grid>
          <Grid item sx={{ mb: "10px" }}>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="primary"
                disabled={loading || exportLoading}
                onClick={() =>
                  retryCall({
                    params: {
                      client: "ultra",
                      operation: "activate",
                    },
                  })
                }
              >
                Retry
              </Button>
            </Stack>
          </Grid>
          {updateValid && <Grid item sx={{ mb: "10px" }}>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="primary"
                disabled={loading || exportLoading}
                onClick={() =>
                  nav('update')
                }
              >
                Update and Retry
              </Button>
            </Stack>
          </Grid>}
          <Grid item sx={{ mb: "10px" }}>
            <TerminateBatch batch_id={batch_id} />
          </Grid>
        </Grid>
      )}
      {response ? (
        <>
          <ActivateList
            data={data}
            loading={loading}
            meta={{ ...metaData, page_size: 100 }}
            client={true}
            handleColumnClick={(key) => copyData(data, [key])}
          />
        </>
      ) : (
        <Title content="Please wait..." variant="subtitle2" gutterBottom />
      )}
    </Box>
  );
};

export default ActivateBatch;
