import React from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { Chip } from "@mui/material";

import DataTable from "../../../common/DataTable";
import ColumnHeader from "../../../common/ColumnHeader";
import { getChipColor } from "../../../../constants/value";

const WifiCallingList = ({
  data,
  meta,
  client,
  loading,
  handleSort,
  disableRowClick,
  handlePageChange,
  handleColumnClick,
  handleFilterChange,
  handlePageSizeChange,
  ...props
}) => {
  const nav = useNavigate();
  const handleRowClick = (e) => {
    nav(`/ultra/wifi_calling/${e.row.id}`);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1, sortable: false },
    {
      field: "iccid",
      headerName: "ICCID",
      minWidth: 200,
      flex: 3,
      sortable: false,
      renderHeader: (params) =>
        client ? (
          <ColumnHeader
            label={"ICCID"}
            handleClick={() => handleColumnClick("iccid")}
          />
        ) : (
          "ICCID"
        ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      flex: 2,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          size="small"
          color={getChipColor(params.row.status)}
        />
      ),
      sortable: false,
    },
    {
      field: "zipcode",
      headerName: "Zipcode",
      minWidth: 75,
      flex: 1,
      sortable: false,
    },
    {
      field: "enable",
      headerName: "Enable",
      flex: 1,
      sortable: false,
      valueGetter: (params) => (params.row.enable ? "True" : "False"),
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      valueGetter: (params) =>
        params.row.created_at &&
        `${format(new Date(params.row.created_at), "MMMM d, y hh:mm a")}`,
    },
    {
      field: "last_updated",
      headerName: "Last Updated",
      minWidth: 200,
      filterable: false,
      flex: 3,
      sortable: false,
      valueGetter: (params) =>
        params.row.last_updated &&
        `${format(new Date(params.row.last_updated), "MMMM d, y hh:mm a")}`,
    },
  ];

  return (
    <DataTable
      disableColumnFilter
      loading={loading}
      data={data}
      columns={
        client ? columns.map((val) => ({ ...val, sortable: true })) : columns
      }
      metaData={meta}
      handleRowClick={disableRowClick ? null : handleRowClick}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      filterMode={client ? "client" : "server"}
      client={client}
      {...props}
    />
  );
};

export default WifiCallingList;
