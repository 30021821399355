import * as yup from "yup";
import { ErrorMessage, useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button, TextareaAutosize } from "@mui/material";

import Title from "../../../common/Title";
import InputFieldBox from "../../../common/InputFieldBox";

import useAlert from "../../../../hooks/useAlert";
import useAxios from "../../../../hooks/useAxios";
import { __getChangeNumber, __updateChangeNumber } from "../../../../api/sim";
import { CLIENTS, CustomError, getValidRetryLines, RETRY_STATUSES } from "../../../../constants/value";

const ChangeNumberUpdate = () => {
  const { setAlertMessage } = useAlert();
  const { batch_id } = useParams();
  const [batchData, setBatchData] = useState([]);
  const [listResponse, listCall, listLoading] = useAxios({ ...__getChangeNumber });
  const [response, call, loading] = useAxios({ ...__updateChangeNumber(batch_id) });
  const nav = useNavigate();

  useEffect(() => {
    if (listResponse && listResponse.status) {
      setBatchData(listResponse.data.data);
    }
  }, [listResponse]);

  useEffect(() => {
    listCall({
      params: {
        client: CLIENTS.ultra,
        page_size: 20000,
        batch_id: batch_id,
      },
    });
  }, [batch_id]);

  useEffect(() => {
    formik.setSubmitting(false);
    if (!response) return;
    if (response.status) {
      setAlertMessage({
        type: "success",
        message: "Change Number data updated and queued for retry",
      });
      nav(`/ultra/change_number/batch/${batch_id}`);
    }
  }, [response]);

  const [validLines, setValidLines] = useState([]);
  const [rawData, setRawData] = useState("");
  const [linesData, setLinesData] = useState([]);
  const [skipZipCheck, setSkipZipCheck] = useState(false);

  const initialData = { lines: [] };

  const lineValidationSchema = yup.object({
    iccid: yup
      .string("iccid")
      .min(19, "Should be exactly 19 or 20 digits long")
      .max(20, "Should be exactly 19 or 20 digits long")
      .required(),
    areacode: yup
      .string("areacode")
      .test("Digits only", "The field should have 3 digits only", (value) =>
        value && value.length ? /^\d{3}$/.test(value) : true
      )
      .optional(),
    zipcode: yup
      .string("areacode")
      .test("Digits only", "The field should have 5 digits only", (value) =>
        value && value.length ? /^\d{5}$/.test(value) : true
      )
      .optional(),
  });

  useEffect(() => {
    const retryLines = getValidRetryLines(batchData);

    setValidLines(retryLines.map((item) => item.iccid));
    retryLines &&
      setRawData(
        retryLines
          .reduce((acc, item) => {
            acc.push(`${item.iccid},${item.areacode},${item.zipcode}`);
            return acc;
          }, [])
          .join("\n")
      );
  }, [batchData]);

  const validateFunction = (data) => {
    const errors = {};
    const formatedLines = [];

    if (!data) {
      return errors;
    }

    const lines = data.split("\n");
    lines.some((line, idx) => {
      const data = line.split(",");
      if (2 > data.length > 4) {
        errors.lines = `Invalid format in line ${idx + 1}: ${line}`;
        return true;
      }

      try {
        const line_data = line.split(",");
        const obtainedData =
          line_data.length === 1
            ? { iccid: line_data[0].trim() }
            : line_data.length === 2
            ? {
                iccid: line_data[0].trim(),
                areacode: line_data[1].trim(),
              }
            : {
                iccid: line_data[0].trim(),
                areacode: line_data[1].trim(),
                zipcode: line_data[2].trim(),
              };

        if (validLines && !validLines.includes(obtainedData.iccid)) {
          throw new CustomError("Invalid Line selected");
        }

        lineValidationSchema.validateSync(obtainedData);

        if (isNaN(obtainedData.iccid)) {
          throw new Error("Invalid Number");
        }
        formatedLines.push(obtainedData);
      } catch (err) {
        console.log(err);
        errors.lines = `Invalid ${
          err instanceof CustomError ? "Line status" : "format"
        } in line ${idx + 1}: ${line}`;
        return true;
      }

      return false;
    });

    if (!errors.lines) {
      setLinesData(formatedLines);
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: { ...initialData },
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        obj[key] = values[key] || null;
        return obj;
      }, {});

      call({
        data: {
          skip_zip_checks: skipZipCheck,
          plan: requestData.plan,
          lines: [...linesData],
        },
      });
    },
    validateOnChange: true,
  });

  const handleParse = (inputFile) => {
    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      setRawData(target.result);
      formik.setErrors(validateFunction(target.result));
    };
    reader.readAsText(inputFile);
  };
  const handleFileChange = (e) => {
    const allowedExtensions = ["csv"];

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        formik.setErrors({
          ...formik.errors.lines,
          upload: "Please input a csv file",
        });
        return;
      }

      handleParse(inputFile);
    }
  };

  if (loading || listLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Box
        sx={{ width: "100%" }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Title
          variant="h5"
          content="Update Change Number Sims"
          sx={{ mb: 3 }}
          gutterBottom
        />
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <FormControl
              error={Boolean(formik.errors.upload) ? true : false}
              sx={{ width: "100%" }}
            >
              <Button
                component="label"
                variant="outlined"
                startIcon={<UploadFileIcon />}
                sx={{ marginRight: "1rem" }}
              >
                Upload CSV
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              {Boolean(formik.errors.upload) ? (
                <FormHelperText id="upload-error-text">
                  {formik.errors.upload}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              error={Boolean(formik.errors.skip_check) ? true : false}
              sx={{ width: "100%" }}
            >
              <InputFieldBox
                isCheckbox={true}
                value={skipZipCheck}
                onChange={(event) => setSkipZipCheck(event.target.checked)}
                label="Skip Zip checks"
              />

              {Boolean(formik.errors.skip_check) ? (
                <FormHelperText id="skip_check-error-text">
                  {formik.errors.skip_check}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="lines">
              {"Add in the format"}
              <br></br>
              {"ICCID,Areacode,Zipcode"}
              <br></br>
              {"ICCID,Areacode,Zipcode"}
              <br></br>
              <em>
                {"Note: Areacode is 3 digits and Zipcode is 5 digits long."}
              </em>
            </InputLabel>
            <FormControl
              error={Boolean(formik.errors.lines) ? true : false}
              sx={{ width: "100%" }}
            >
              <TextareaAutosize
                multiline
                rows={6}
                aria-label="minimum height"
                placeholder={`Add in the format\nICCID,Areacode,Zipcode\nICCID,Areacode,Zipcode`}
                style={{ minWidth: 550 }}
                name="lines"
                minRows={5}
                id="lines"
                value={rawData}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const data = e.target.value;
                  setRawData(data);
                  formik.setErrors(validateFunction(data));
                }}
              />
              {Boolean(formik.errors.lines) ? (
                <FormHelperText id="lines-error-text">
                  {formik.errors.lines}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid
            container
            sx={{ mt: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                disabled={formik.errors.lines || !linesData.length}
                onClick={() =>
                  !loading && !formik.isSubmitting && formik.submitForm()
                }
                size="large"
                loading={loading}
                variant="contained"
              >
                {"Update and Retry"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ChangeNumberUpdate;
